<template>
    <div class="poster">
        <div class="content">
            <div class="qrcode_box">
                <img src="" alt="" class="qrcode"/>
            </div>
            <div class="phone">推荐码 18700000000</div>
            <div class="share">
                <div class="li" @click="shareDownload">
                    <img src="@/assets/activeImgs/share_pyq.png" alt="" class="share_pyq"/>
                    <span>朋友圈</span>
                </div>
                <div class="li">
                    <img src="@/assets/activeImgs/share_user.png" alt="" class="share_user"/>
                    <span>微信好友</span>
                </div>
            </div>
            <div class="desc">
                    活动声明：本活动遵循救援云法律声明，规则解释权归
                    救援云所有。凡市在推广期间以任何形式作弊的救援云
                    用户，一经查出，直接取消参与资格，情节严重者做封
                    号处理。
                </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        shareDownload(){
            this.$router.push({
                path: '/sharePoster'
            })
        },
    }
}
</script>

<style lang='scss' scoped>
    .poster{
        width: 100%;
        height: 723px;
        background: url('../../assets/activeImgs/posterBG.png');
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        .content{
            width: 345px;
            height: 483px;
            background: #FFFFFF;
            border-radius: 12px;
            margin-top: 220px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 15px;
            box-sizing: border-box;
            .qrcode_box{
                width: 188px;
                height: 188px;
                background: #FFFFFF;
                border: 2px solid var(--theme-color);
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 45px;
                box-sizing: border-box;
                .qrcode{
                    width: 170px;
                    height: 170px;
                }
            }
            .phone{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 20px;
                color: var(--theme-color);
                letter-spacing: 0.25px;
                margin-top: 10px;
            }
            .share{
                width: 315px;
                height: 80px;
                background: #F4F4FC;
                box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.06);
                opacity: 1;
                border-radius: 9px;
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                .li{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    span{
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 17px;
                        color: #141414;
                        margin-top: 6px;
                    }
                    .share_pyq{
                        width: 23px;
                        height: 23px;
                    }
                    .share_user{
                        width: 27px;
                        height: 23px;
                    }
                }
            }
            .desc{
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 17px;
                color: #141414;
                letter-spacing: 0.5px;
                margin-top: 15px;
            }
        }
    }
</style>